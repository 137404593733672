/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let token = window.csrfTokenValue;
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = window.csrfTokenValue;
}

/**
 * Load/Import Plugins
 * If you don't see a plugin below it might be autloaded via webpack
 * Check webpack.mix.js for autoloaded plugins.
 */
require('jquery-match-height');
require('@fancyapps/fancybox');

/**
 * Import Foundation Components
 * check ./foundation.js if you need to enable more
 */
import { Foundation } from './foundation';
Foundation.addToJquery(jQuery);


/**
 * Application script
 */

// Initialize Foundation
$(document).foundation();

// Additional Logic
$(function() {

   $('[data-fancybox="gallery"]').fancybox({

     // What buttons should appear in the top right corner.
     // Buttons will be created using templates from `btnTpl` option
     // and they will be placed into toolbar (class="fancybox-toolbar"` element)
     buttons: [
       // "zoom",
       //"share",
       // "slideShow",
       //"fullScreen",
       //"download",
       // "thumbs",
       "close"
     ],
     preventCaptionOverlap: false
     
   });

  // Booking form success message
  // Create IE + others compatible event handler
  var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  var eventer = window[eventMethod];
  var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

  // Listen to message from child window
  eventer(messageEvent,function(e) {
    if(e.data === "success") {
      var $bookingSuccess = $(".js-booking-success-msg");
      var $bookingForm = $(".js-booking-form");
      $bookingForm.slideUp(500);
      $bookingSuccess.show(500);
    }
  },false);

});
